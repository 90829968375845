import React, { useEffect, useState } from "react";
import { ModalComponent } from "../../components/modal/ModalComponent";
import { Stack, Typography, Button, TextField, Box } from "@mui/material";
import useTranslation from "../../../localization/useTranslation";
import { checkIsUrlValid } from "../../../utils";
import { useDispatch } from "react-redux";
import { changeContentByBitlyLink, searchCampaignContentByLink } from "../../../redux/ducks/campaignsDucks";

export const BitlyLinkModal = ({ isOpened, handleClose, campaignId }) => {
  const { labelStrings } = useTranslation();
  const dispatch = useDispatch()

  const [fullPlatformLink, setFullPlatformLink] = useState("");
  const [error, setError] = useState(false);

  const onClose = () => {
    handleClose();
    setFullPlatformLink("");
    setError(false);
  }

  const handleChange = (event) => {
    const inputUrl = event.target.value;
    setFullPlatformLink(inputUrl);
    if (!checkIsUrlValid(inputUrl) && inputUrl?.length) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleSearch = () => {
    dispatch([
      searchCampaignContentByLink({ link: fullPlatformLink, campaignId }),
      changeContentByBitlyLink({
        link: fullPlatformLink
      })
    ]);
    
    onClose();
  };

  return (
    <ModalComponent
      isOpenModal={isOpened}
      closeModal={onClose}
      title={labelStrings.searchOrAddContentByLink}
      contentWidth='500px'
      contentHeight='auto'
      maxHeightForScrollbarContent='400px'
      isCenterTitle
      smallPadding
    >
      <Stack spacing={2} sx={{ pb: 2 }} alignItems='center'>
        <Typography
          textAlign='center'
          sx={{ fontFamily: "Inter", whiteSpace: "pre-wrap" }}
        >
          {labelStrings.searchOrAddContentByLinkSubtitle}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <TextField
            label={labelStrings.fullPlatformLink}
            fullWidth
            size='small'
            value={fullPlatformLink}
            onChange={handleChange}
            error={error}
            helperText={error ? "URL must start with 'https://'" : ""}
          />
        </Box>
        <Button disabled={error || !fullPlatformLink?.length} onClick={handleSearch} variant='outlined'>
          {labelStrings.search}
        </Button>
      </Stack>
    </ModalComponent>
  );
};
