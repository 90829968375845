import React from "react"
import { Box, Card, Stack, Tooltip, Button } from "@mui/material"
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import Label from "../../label"
import { getUrlWithProxy } from "../../../../utils"
import { downloadMedia } from "./utils"
import useTranslation from "../../../../localization/useTranslation"

export const WrapperMediaItem = (props) => {
  const {
    children,
    widthElement,
    heightElement,
    isDataEnrichment,
    contentVideo,
    contentImg,
    contentType,
    platform,
    onHandleSetCurrCreatorsData,
    isDownloadContentModal,
    onGetSimilarContentPieces,
  } = props
  const { labelStrings } = useTranslation()

  const saveAs = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const handleDownload = async (url, fileName) => {
    if (!url.trim()) {
      return
    }

    const currentDate = new Date()
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace(/:/g, "-")
      .replace("T", "_")

    const proxyUrl = getUrlWithProxy(url)
    const mediaExtension = url?.split(".")?.pop()?.split("?")[0]?.toLowerCase()

    if (url?.includes("infludata")) {
      return downloadMedia(getUrlWithProxy(url), mediaExtension)
    }

    try {
      const response = await fetch(proxyUrl)
      const blob = await response.blob()
      const blobType = blob?.type?.split("/")

      if (blobType?.length) {
        saveAs(
          blob,
          `${fileName || blobType[0]}-${formattedDate}.${blobType[1] || mediaExtension}`,
        )
      }
    } catch (error) {
      console.error("Error downloading media:", error)
    }
  }

  return (
    <Box sx={{ width: " 100%", height: "100%", position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: "2px",
          display: "flex !important",
          flexDirection: "column",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            zIndex: 9,
            position: "absolute",
            top: 0,
            right: 0,
            padding: "10px",
          }}
        >
          {!isDataEnrichment ? (
            <Box sx={{ cursor: "pointer " }}>
              {isDownloadContentModal ? (
                <Stack direction='row' alignItems='center' spacing={0.5}>
                  <Box>
                    <Tooltip title={labelStrings.similarContentTooltip}>
                      <Button
                        size='small'
                        sx={{
                          backgroundColor: "#F9FAFB",
                          color: "#212B36",
                          minWidth: "20px",
                          height: "30px",
                          width: "30px",
                          borderRadius: "5px",
                          "&:hover": {
                            backgroundColor: "#F9FAFB",
                            opacity: 0.8,
                          },
                        }}
                        onClick={onGetSimilarContentPieces}
                      >
                        <AutoFixHighOutlinedIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                  <Button
                    sx={{
                      backgroundColor: "#F9FAFB",
                      color: "#212B36",
                      minWidth: "20px",
                      height: "30px",
                      width: "30px",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "#F9FAFB",
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => onHandleSetCurrCreatorsData()}
                  >
                    <FileDownloadOutlinedIcon />
                  </Button>
                </Stack>
              ) : (
                <Stack direction='row' alignItems='center' spacing={0.5}>
                  <Box>
                    <Tooltip title={labelStrings.similarContentTooltip}>
                      <Button
                        size='small'
                        sx={{
                          backgroundColor: "#F9FAFB",
                          color: "#212B36",
                          minWidth: "20px",
                          height: "30px",
                          width: "30px",
                          borderRadius: "5px",
                          "&:hover": {
                            backgroundColor: "#F9FAFB",
                            opacity: 0.8,
                          },
                        }}
                        onClick={onGetSimilarContentPieces}
                      >
                        <AutoFixHighOutlinedIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                  <Button
                    sx={{
                      backgroundColor: "#F9FAFB",
                      color: "#212B36",
                      minWidth: "20px",
                      height: "30px",
                      width: "30px",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "#F9FAFB",
                        opacity: 0.8,
                      },
                    }}
                    onClick={() =>
                      handleDownload(contentVideo ? contentVideo : contentImg)
                    }
                  >
                    <FileDownloadOutlinedIcon />
                  </Button>
                </Stack>
              )}
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
        <Box>
          <Card
            sx={{
              width: `calc(${widthElement}px - 20px) !important`,
              height: `calc(520px - ${heightElement}px)`,
            }}
          >
            {children}
          </Card>
        </Box>
        <Stack
          direction='row'
          justifyContent='start'
          alignItems='center'
          spacing={1} // Adjust this value to increase/decrease space between labels
          sx={{
            width: "100% !important",
            position: "absolute",
            top: 0,
            left: 0,
            padding: "10px",
          }}
        >
          <Label
            variant='filled'
            color={
              (contentType === "shorts" && "error") ||
              (contentType === "reel" && "info") ||
              (contentType === "video" &&
                platform === "tiktok" &&
                "secondary") ||
              (platform === "youtube" && "error") ||
              (contentType === "post" && "warning") ||
              "success"
            }
          >
            {platform + " " + contentType}
          </Label>
        </Stack>
      </Box>
    </Box>
  )
}
