import React, { Fragment, useRef, useState } from 'react'
import Carousel, { CarouselArrowIndex } from '../index'
import { Box, Card, IconButton, Stack } from '@mui/material'
import Image from '../../image'
import { PlayingVideo } from './PlayingVideo'
import Label from '../../label'
import { DownloadContent } from '../../../assets/icons/navbar'
import { getUrlWithProxy } from '../../../../utils'
import { downloadMedia } from './utils'

export const GroupItemCarousel = props => {
  const {
    imageUrls,
    videoUrls,
    heightElement,
    widthElement,
    onHandleSetCurrCreatorsData,
    isDownloadContentModal,
  } = props
  const carouselRef = useRef(null)

  const [currSlide, setCurrSlide] = useState(0)
  const [startVideo, setStartVideo] = useState(false)

  const onHandlePlayVideo = () => {
    setStartVideo(!startVideo)
  }

  const config = {
    dots: false,
    arrows: false,
    slidesToScroll: 1,
    rows: 1,
    slidesToShow: 1,
    className: 'padding',
    lazyLoad: true,
    // focusOnSelect: true,
    speed: 500,
    infinite: false,
    afterChange: currentSlide => {
      setCurrSlide(currentSlide)
    },
  }

  const handlePrev = () => {
    carouselRef.current?.slickPrev()
  }

  const handleNext = () => {
    carouselRef.current?.slickNext()
  }

  const generateData = (videos, images) => {
    const mergedData = []

    for (let i = 0; i < Math.max(videos.length, images.length); i++) {
      if (videos[i]) {
        mergedData.push({ type: 'video', content: videos[i] })
      } else if (images[i]) {
        mergedData.push({ type: 'image', content: images[i] })
      }
    }

    return mergedData
  }

  const data = generateData(videoUrls, imageUrls)

  const handleDownload = () => {
    if (isDownloadContentModal) {
      onHandleSetCurrCreatorsData()
      return
    }

    if (videoUrls?.length && videoUrls.some((x, idx) => x && idx === currSlide)) {
      downloadMedia(getUrlWithProxy(videoUrls[currSlide || 0] || ''), 'mp4', currSlide)
    } else if (imageUrls?.length && imageUrls.some(x => x)) {
      downloadMedia(getUrlWithProxy(imageUrls[currSlide || 0] || ''), 'jpeg', currSlide)
    }
  }

  return (
    <Box sx={{ width: ' 100%', height: '100%', position: 'relative' }}>
      <Box
        sx={{
          width: `calc(${widthElement}px - 16px) !important`,
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex !important',
          flexDirection: 'column',
          alignItems: 'end',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            cursor: 'pointer',
            zIndex: 9,
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '10px',
            pt: 0,
            pr: 0
          }}
        >
          <IconButton onClick={() => handleDownload()}>
            <DownloadContent />
          </IconButton>
        </Box>
        <Carousel
          ref={carouselRef}
          {...config}
          style={{ width: '100%', height: `calc(520px - ${heightElement}px)` }}
        >
          {data?.map((item, idx) => {
            if (item.type === 'video') {
              return (
                <Card sx={{ width: '100%', height: `calc(520px - ${heightElement}px)` }} key={idx}>
                  <PlayingVideo
                    multipleVideo={videoUrls}
                    multipleVideoPreview={imageUrls}
                    startVideo={startVideo}
                    setStartVideo={setStartVideo}
                    onHandlePlayVideo={onHandlePlayVideo}
                    videoIndexFromCarousel={idx}
                    setCurrSlide={setCurrSlide}
                  />
                </Card>
              )
            } else {
              return (
                <Fragment key={idx}>
                  <Image
                    alt="contain"
                    src={item.content}
                    ratio="1/1"
                    sx={{ borderRadius: 1.5, height: `calc(520px - ${heightElement}px)` }}
                  />
                </Fragment>
              )
            }
          })}
        </Carousel>
        <Stack
          direction="column" // Adjusted this to column
          justifyContent="center" // Adjusted this to center
          alignItems="start" // Adjusted this to start
          spacing={0.5}
          sx={{
            width: '100% !important',
            position: 'absolute',
            top: 0,
            left: 0,
            padding: '10px',
            // height: '50px'
          }}
        >
          <Label variant="filled" color="success" sx={{ zIndex: 9 }}>
            instagram story group
          </Label>
          <CarouselArrowIndex
            onNext={handleNext}
            onPrevious={handlePrev}
            disabledPrev={currSlide === 0}
            disabledNext={currSlide === data?.length / config.rows - config.slidesToShow}
            index={currSlide}
            total={data?.length}
            sx={{ fontSize: '0.8rem' }} // Adjusted the styling directly here
          />
        </Stack>
      </Box>
    </Box>
  )
}
