import React from 'react'
import PropTypes from 'prop-types'
import { Box, styled, Modal, Typography, Card, Fade, Backdrop, Stack } from '@mui/material'
import Scrollbar from '../scrollbar/Scrollbar'

const ContentWrapper = styled(Card)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1158px',
  height: 'calc(100vh / 1.25)',
  overflow: 'inherit',
})

export const ModalComponent = ({
  isOpenModal = false,
  closeModal,
  children = null,
  title = '',
  contentWidth = '1158px',
  contentHeight = 'calc(100vh / 1.25)',
  smallPadding = false,
  smallPaddingContent = false,
  isCenterTitle = false,
  isFixedContentHeight = true,
  withoutScrollWrapper,
  disableEscapeKeyDown,
  maxHeightForScrollbarContent = '',
}) => {
  return (
    <div>
      <Modal
        keepMounted
        open={isOpenModal}
        onClose={closeModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 350,
          },
        }}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <Fade in={isOpenModal}>
          <ContentWrapper
            sx={{ width: contentWidth, height: isFixedContentHeight ? contentHeight : 'auto' }}
          >
            {!!title?.length && (
              <Box sx={{ p: smallPadding ? 2 : 4, textAlign: isCenterTitle ? 'center' : 'start' }}>
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ color: '#212B36', fontWeight: 700, fontSize: '18px', lineHeight: '28px', fontFamily: 'Inter' }}
                >
                  {title}
                </Typography>
              </Box>
            )}
            {withoutScrollWrapper ? (
              <Stack sx={{ width: '100%', px: smallPaddingContent ? 2 : 5 }}>{children}</Stack>
            ) : (
              <Scrollbar
                sx={{
                  maxHeight: maxHeightForScrollbarContent
                    ? maxHeightForScrollbarContent
                    : `calc(100vh / 1.5)`,
                  width: '100%',
                  px: smallPaddingContent ? 2 : 5,
                }}
              >
                {children}
              </Scrollbar>
            )}
          </ContentWrapper>
        </Fade>
      </Modal>
    </div>
  )
}

ModalComponent.propTypes = {
  isOpenModal: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  contentWidth: PropTypes.string,
  contentHeight: PropTypes.string,
  smallPadding: PropTypes.bool,
  smallPaddingContent: PropTypes.bool,
  isCenterTitle: PropTypes.bool,
  isFixedContentHeight: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  maxHeightForScrollbarContent: PropTypes.string,
}