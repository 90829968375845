import React from "react";
import { Box, Grid, Stack, Typography, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PublishedItem } from "../../components/carousel/published-content/PublishedItem";
import useTranslation from "../../../localization/useTranslation";
import { shallowEqual, useSelector } from "react-redux";
import { userGrantSelector } from "../../../redux/selectors";
import EmptyContent from "../../components/empty-content";

export const CarouselContent = ({
  listData,
  openModal,
  setEditItem,
  setPage,
  isShowMoreLoading,
  totalContentPieces,
  openUnmatchedContent,
  onAddToCampaign,
  setCurrentCreatorsData,
  hasMoreItems,
  isBitlyLinkContent,
  fromBitlyLinkData,
}) => {
  const { labelStrings } = useTranslation();
  const { campaign_content_limit_days } =
    useSelector(userGrantSelector, shallowEqual) || {};

  const { link, status, contentArray, isMatched } = fromBitlyLinkData || {};

  const isErrorStatusLabel = !contentArray?.length && status === "error";
  const isWeCouldNotFind = !contentArray?.length && status === "ok";
  const isOkBitly = contentArray?.length > 0;

  const isAddToCampaign = isOkBitly && !!isMatched

  const onShowMore = () => {
    setPage((page) => page + 1);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        {isBitlyLinkContent ? (
          <Grid item xs={12} md={12} sx={{ mb: 2 }}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "28px",
                  fontWeight: 700,
                  FontFamily: "Inter",
                }}
              >
                {labelStrings.campaignContentOverview.titleRecognizedContent} -
              </Typography>
              <Chip
                size='small'
                label={`URL: ${link}`}
                sx={{
                  fontSize: "14px",
                  lineHeight: "28px",
                  fontWeight: 700,
                  FontFamily: "Inter",
                }}
              />
            </Stack>
            {!isOkBitly && (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#637381",
                  fontWeight: 400,
                  FontFamily: "Inter",
                  mt: 0.5
                }}
              >
                {isWeCouldNotFind && labelStrings.weCouldntFind}
                {isErrorStatusLabel && labelStrings.theLinkYouPostedNotValid}
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} md={12} sx={{ mb: 2 }}>
            <Typography
              sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: 700 }}
            >
              {labelStrings.campaignContentOverview.titleRecognizedContent}{" "}
              {openUnmatchedContent &&
                labelStrings.campaignContentOverview.titleNonRecognizedContent}
            </Typography>
            {openUnmatchedContent ? (
              <Typography
                sx={{ fontSize: "14px", color: "#637381", fontWeight: 400 }}
              >
                {
                  labelStrings.campaignContentOverview
                    .subTitleNonRecognizedContent
                }
              </Typography>
            ) : (
              <Stack direction='row' alignItems='center' spacing={0.5}>
                <Typography
                  sx={{ fontSize: "14px", color: "#637381", fontWeight: 400 }}
                >
                  {totalContentPieces || 0}{" "}
                  {
                    labelStrings.campaignContentOverview
                      .subTitleRecognizedContent
                  }
                  .
                </Typography>

                {campaign_content_limit_days &&
                  campaign_content_limit_days <= 365 && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#637381",
                        fontWeight: 400,
                      }}
                    >
                      {labelStrings.youCanViewUpToDays(
                        Math.round(campaign_content_limit_days),
                      )}
                      .
                    </Typography>
                  )}
              </Stack>
            )}
            <Typography
              sx={{ fontSize: "14px", color: "#637381", fontWeight: 400 }}
            >
              {
                labelStrings.campaignContentOverview
                  .subTitleTakesTimeUntilVisible
              }
            </Typography>
          </Grid>
        )}
        {listData?.length ? (
          listData?.map((item) => {
            return (
              <Grid item xs={12} md={3} key={item.id}>
                <PublishedItem
                  item={item}
                  isNotWidget={true}
                  openModal={openModal}
                  setEditItem={setEditItem}
                  isDataEnrichment={false}
                  openUnmatchedContent={openUnmatchedContent || isAddToCampaign}
                  onAddToCampaign={onAddToCampaign}
                  setCurrentCreatorsData={setCurrentCreatorsData}
                />
              </Grid>
            );
          })
        ) : (
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{ width: "100%", height: "calc(100vh - 400px)" }}
          >
            <EmptyContent
              title={labelStrings.couldntFindAnyContent}
              sx={{
                "& span.MuiBox-root": { height: 160 },
              }}
            />
          </Stack>
        )}
      </Grid>
      {hasMoreItems && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingButton
            loading={isShowMoreLoading}
            onClick={onShowMore}
            variant='outlined'
          >
            {labelStrings.showMore}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};
