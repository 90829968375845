import React from 'react'
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import { NestedMenuItem } from 'mui-nested-menu'
import MenuPopover from '../../components/menu-popover'
import useTranslation from '../../../localization/useTranslation'
import { useSelector } from 'react-redux'
import { currentOpenedCampaignSelector, isExceedingCampaignLimit } from '../../../redux/selectors'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Scrollbar from '../../components/scrollbar/Scrollbar'

export const PopoverItemMenu = props => {
  const { labelStrings } = useTranslation()
  const { dashboards, isOnboarding: isOnboardingCampaign } =
    useSelector(currentOpenedCampaignSelector) || {}
  const isDisabledByCampaignLimit = useSelector(isExceedingCampaignLimit)

  const {
    openPopover,
    handleClosePopover,
    onEditRow,
    onArchiveRow,
    rowIsBrandAccount,
    onAddRemoveBrandAccount,
    handleViewEditCreator,
    onAddNonRecognizedContent,
    setMatchedModalData,
    onViewCreatorAnalytics,
    status,
    onActivateCreator,
    rowData,
    setOpenArchiveModal,
    setOpenDeleteModal
  } = props

  const isArchived = status === 'archived'
  const isOnboarding = status === 'onboarding'
  const isActive = status === 'active'

  const bitlyLinksArray = rowData?.fromCampaignData?.bitlyLinks

  const onCopy = copyText => {
    navigator.clipboard.writeText(copyText ? copyText : '')
    handleClosePopover()
  }

  return (
    <>
      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top">
        {isArchived &&
          <MenuItem
            onClick={() => {
              setOpenDeleteModal()
              handleClosePopover()
            }}
          >
            {labelStrings.campaignCreatorOverview.popoverMenu.deleteCreator}
          </MenuItem>
        }
        {isOnboarding ? (
          <>
            <MenuItem
              onClick={() => {
                onArchiveRow()
                handleClosePopover()
              }}
            >
              {isArchived
                ? labelStrings.campaignCreatorOverview.popoverMenu.unarchiveCreator
                : labelStrings.campaignCreatorOverview.popoverMenu.archiveCreator}
            </MenuItem>
            <Tooltip
              title={
                isDisabledByCampaignLimit
                  ? labelStrings.disabledTextForCampaignLimit
                  : isOnboardingCampaign
                  ? labelStrings.activateCampaignFirst
                  : ''
              }
              placement="bottom-start"
              arrow
            >
              <div>
                <MenuItem
                  disabled={isOnboardingCampaign || isDisabledByCampaignLimit}
                  onClick={() => {
                    if (isDisabledByCampaignLimit || isOnboardingCampaign) return
                    onActivateCreator()
                    handleClosePopover()
                  }}
                >
                  {labelStrings.campaignCreatorOverview.popoverMenu.activateCreator}
                </MenuItem>
              </div>
            </Tooltip>
          </>
        ) : (
          <>
            <MenuItem
              disabled={isArchived}
              onClick={() => {
                handleViewEditCreator()
                handleClosePopover()
              }}
            >
              {labelStrings.campaignCreatorOverview.popoverMenu.viewEdit}
            </MenuItem>

            <NestedMenuItem
              disabled={isArchived}
              label={labelStrings.campaignCreatorOverview.popoverMenu.viewCreatorAnalytics}
              parentMenuOpen={isArchived ? false : !!openPopover}
              sx={{
                '& .MuiTypography-root': {
                  paddingLeft: '0',
                  paddingRight: '0',
                  lineHeight: '1.5714285714285714',
                  fontSize: ' 0.875rem',
                  fontFamily: 'Public Sans,sans-serif',
                  fontWeight: 400,
                  borderRadius: '6px',
                },
              }}
            >
              <MenuItem
                disabled
                sx={{
                  mb: 0.5,
                  width: '100%',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {labelStrings.selectDashboard}
              </MenuItem>
              {dashboards?.map(dashboard => {
                return (
                  <MenuItem
                    onClick={() => {
                      onViewCreatorAnalytics(dashboard.dashboardId)
                      handleClosePopover()
                    }}
                    key={dashboard.dashboardId}
                  >
                    {dashboard.dashboardName}
                  </MenuItem>
                )
              })}
            </NestedMenuItem>

            <MenuItem
              disabled={isArchived}
              onClick={() => {
                onEditRow()
                handleClosePopover()
              }}
            >
              {labelStrings.campaignCreatorOverview.popoverMenu.editCreator}
            </MenuItem>

            {!isArchived && (
              <MenuItem
                onClick={() => {
                  setOpenArchiveModal()
                  handleClosePopover()
                }}
              >
                {labelStrings.campaignCreatorOverview.popoverMenu.archiveCreator}
              </MenuItem>
            )}

            {isActive && 
              <Tooltip
                title={labelStrings.deleteCreatorFromCampaignsTipLabel}
                placement="bottom-start"
                arrow
              >
                <div>
                  <MenuItem
                    disabled={true}
                  >
                    {labelStrings.campaignCreatorOverview.popoverMenu.deleteCreator}
                  </MenuItem>
                </div>
              </Tooltip> 
            }

            <MenuItem
              disabled={isArchived}
              onClick={() => {
                onAddNonRecognizedContent()
                handleClosePopover()
              }}
            >
              {labelStrings.campaignCreatorOverview.popoverMenu.addNonRecognizedContent}
            </MenuItem>
            <MenuItem
              disabled={rowIsBrandAccount || isArchived}
              onClick={() => {
                onAddRemoveBrandAccount()
                handleClosePopover()
              }}
            >
              {labelStrings.campaignCreatorOverview.popoverMenu.addAsBrandAccount}
            </MenuItem>
            <MenuItem
              disabled={isArchived || !rowIsBrandAccount}
              onClick={() => {
                onAddRemoveBrandAccount()
                handleClosePopover()
              }}
            >
              {labelStrings.campaignCreatorOverview.popoverMenu.removeAsBrandAccount}
            </MenuItem>
            <MenuItem
              disabled={isArchived}
              onClick={() => {
                setMatchedModalData()
                handleClosePopover()
              }}
            >
              {labelStrings.campaignCreatorOverview.matchModal.title}
            </MenuItem>

            {!!bitlyLinksArray?.length && (
              <NestedMenuItem
                disabled={isArchived}
                label={labelStrings.showAndCopyBitlyLink}
                parentMenuOpen={isArchived ? false : !!openPopover}
                sx={{
                  '& .MuiTypography-root': {
                    paddingLeft: '0',
                    paddingRight: '0',
                    lineHeight: '1.5714285714285714',
                    fontSize: ' 0.875rem',
                    fontFamily: 'Public Sans,sans-serif',
                    fontWeight: 400,
                    borderRadius: '6px',
                  },
                }}
              >
                <Scrollbar sx={{ maxHeight: '180px', width: '300px' }}>
                  <Stack spacing={1.5} sx={{ p: 1 }}>
                    {bitlyLinksArray?.map(field => {
                      return (
                        <Box key={field?.customFieldId}>
                          <TextField
                            size="small"
                            label={field?.longUrl}
                            sx={{
                              '& .MuiInputBase-input': {
                                color: '#637381',
                              },
                            }}
                            value={field?.shortenedUrl}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => onCopy(field?.shortenedUrl)}>
                                    <ContentCopyIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      )
                    })}
                  </Stack>
                </Scrollbar>
              </NestedMenuItem>
            )}
          </>
        )}
      </MenuPopover>
    </>
  )
}
