import { actionProvider } from './actionsProvider'
import { setError } from './errorDucks'
import { ERROR_MSG } from '../../constants/errorMessages'
import { SORTING_DIRECTIONS, CAMPAIGNS_SORTING_TYPES } from '../../constants/sortings'
import { CAMPAIGN_SHOW_HITS_FILTER } from '../../constants/filters'
import httpService from '../../services/httpService'
import localStorageService from '../../services/localStorageService'
import { sortArrayOfObjects } from '../../utils'
import {
  currentOpenedCampaignSelector,
  allCampaignsSelector,
  isSingleCampaignLoadingSelector,
  currentCampaignSortingSelector,
  currentOpenedCampaignUsersSelector,
  userSubSelector,
  refreshGrantSelector,
  userCredsLoadProgressSelector,
  getAllCampaignsSelector,
  contentListSelector,
  campaignDashboard,
} from '../selectors'
import {
  modifyProfileElementEverywhere,
  modifyElementsInCampaignsEverywhere,
  changeCampaignLimitTrackNow,
} from './profilesCommonActions'
import { toggleUserCredsLoad } from './userDucks'
import { toast } from 'react-toastify'
import keyBy from 'lodash/keyBy'
import { API_STATUS_CODES } from '../../constants/appSettings'
import { format } from 'date-fns'
import { dateFormatMaskYYYY_MM_dd } from '../../new-ui/sections/dashboard/utils'
import { ToastAddCreatorToCampaign } from '../../components/toasts/ToastAddCreatorToCampaign'

// action types
const SET_ALL_CAMPAIGNS = 'SET_ALL_CAMPAIGNS'
const CHANGE_CAMPAIGNS_LOADING_STATUS = 'CHANGE_CAMPAIGNS_LOADING_STATUS'
const CHANGE_SINGLE_CAMPAIGN_LOADING_STATUS = 'CHANGE_SINGLE_CAMPAIGN_LOADING_STATUS'
const SET_CURRENT_OPENED_CAMPAIGN = 'SET_CURRENT_OPENED_CAMPAIGN'
const RESET_CAMPAIGNS = 'RESET_CAMPAIGNS'
const CHANGE_USERS_IN_OPENED_CAMPAIGN = 'CHANGE_USERS_IN_OPENED_CAMPAIGN'
const CHANGE_OPEN_CAMPAIGN_SORTING = 'CHANGE_OPEN_CAMPAIGN_SORTING'
const CHANGE_OPEN_CAMPAIGN_FILTERS = 'CHANGE_OPEN_CAMPAIGN_FILTERS'
const CHANGE_CURRENT_CAMPAIGN_LOADING = 'CHANGE_CURRENT_CAMPAIGN_LOADING'

const CHANGE_NEW_CAMPAIGN_DATA = 'CHANGE_NEW_CAMPAIGN_DATA'
const CHANGE_NEW_CAMPAIGNS_LOADING = 'CHANGE_NEW_CAMPAIGNS_LOADING'

/* Dashboard */
const CHANGE_DASHBOARD_DATA = 'CHANGE_DASHBOARD_DATA'
const CHANGE_DASHBOARD_ID = 'CHANGE_DASHBOARD_ID'
const CHANGE_DASHBOARD_ERROR = 'CHANGE_DASHBOARD_ERROR'
const CHANGE_DASHBOARD_LOADING = 'CHANGE_DASHBOARD_LOADING'
const CHANGE_DASHBOARD_IS_RUNNING_FETCH = 'CHANGE_DASHBOARD_IS_RUNNING_FETCH'
const CHANGE_IS_EMPTY_VARIABLES = 'CHANGE_IS_EMPTY_VARIABLES'

/* Content Overview */
const CHANGE_CONTENT_LIST_SHOW_MORE_BTN_LOADING = 'CHANGE_CONTENT_LIST_SHOW_MORE_BTN_LOADING'
const CHANGE_CONTENT_LIST_LOADING = 'CHANGE_CONTENT_LIST_LOADING'
const CHANGE_CONTENT_LIST_DATA = 'CHANGE_CONTENT_LIST_DATA'
const CHANGE_CONTENT_LIST_ERROR = 'CHANGE_CONTENT_LIST_ERROR'
const CHANGE_CONTENT_LIST_NON_RECOGNIZED_LOADING = 'CHANGE_CONTENT_LIST_NON_RECOGNIZED_LOADING'
const CHANGE_CONTENT_LIST_NON_RECOGNIZED_DATA_SUCCESS =
  'CHANGE_CONTENT_LIST_NON_RECOGNIZED_DATA_SUCCESS'
const CHANGE_CONTENT_LIST_BY_BITLY_LINK = 'CHANGE_CONTENT_LIST_BY_BITLY_LINK'
/* Creator Overview */
const CHANGE_CREATOR_DATA = 'CHANGE_CREATOR_DATA'
const CHANGE_CREATOR_LOADING = 'CHANGE_CREATOR_LOADING'
const CHANGE_CREATOR_ERROR = 'CHANGE_CREATOR_ERROR'
/* Calendar */
const CHANGE_CALENDAR_LOADING = 'CHANGE_CALENDAR_LOADING'
const CHANGE_CALENDAR_ERROR = 'CHANGE_CALENDAR_ERROR'
const CHANGE_CALENDAR_EVENTS = 'CHANGE_CALENDAR_EVENTS'
/* Goals */
const CHANGE_GOALS_LOADING = 'CHANGE_GOALS_LOADING'
const CHANGE_GOALS_ERROR = 'CHANGE_GOALS_ERROR'
const CHANGE_GOALS_DATA = 'CHANGE_GOALS_DATA'
/* Tracked Metrics */
const CHANGE_TRACKED_METRICS_LOADING = 'CHANGE_TRACKED_METRICS_LOADING'
const CHANGE_TRACKED_METRICS_ERROR = 'CHANGE_TRACKED_METRICS_ERROR'
const CHANGE_TRACKED_METRICS_DATA = 'CHANGE_TRACKED_METRICS_DATA'
/* Custom Fields */
const CHANGE_CUSTOM_FIELDS_LOADING = 'CHANGE_CUSTOM_FIELDS_LOADING'
const CHANGE_CUSTOM_FIELDS_ERROR = 'CHANGE_CUSTOM_FIELDS_ERROR'
const CHANGE_CUSTOM_FIELDS_DATA = 'CHANGE_CUSTOM_FIELDS_DATA'
/* Pipeline */
const CHANGE_PIPELINE_LOADING = 'CHANGE_PIPELINE_LOADING'
const CHANGE_PIPELINE_ERROR = 'CHANGE_PIPELINE_ERROR'
const CHANGE_PIPELINE_BOARD_CARDS = 'CHANGE_PIPELINE_BOARD_CARDS'
const CHANGE_PIPELINE_BOARD_COLUMNS = 'CHANGE_PIPELINE_BOARD_COLUMNS'
const CHANGE_PIPELINE_BOARD_COLUMNS_ORDER = 'CHANGE_PIPELINE_BOARD_COLUMNS_ORDER'
const CHANGE_PIPELINE_BOARD_CARD_LOADING = 'CHANGE_PIPELINE_BOARD_CARD_LOADING'
const CHANGE_PIPELINE_OUT_CAMPAIGN_SEATS = 'CHANGE_PIPELINE_OUT_CAMPAIGN_SEATS'

/* Create Campaign */
const CHANGE_CREATE_CAMPAIGN_TOP_CREATORS = 'CHANGE_CREATE_CAMPAIGN_TOP_CREATORS'
const CHANGE_CREATE_CAMPAIGN_TOP_HASHTAGS = 'CHANGE_CREATE_CAMPAIGN_TOP_HASHTAGS'
const CHANGE_CREATE_CAMPAIGN_STATUS_CODE = 'CHANGE_CREATE_CAMPAIGN_STATUS_CODE'
const CHANGE_NEW_COPY_CAMPAIGN_ID = 'CHANGE_NEW_COPY_CAMPAIGN_ID'

const defaultSorting = {
  sortKey: CAMPAIGNS_SORTING_TYPES.trackingStart.sortKey,
  sortDirection: SORTING_DIRECTIONS.descend,
}

const defaultFilters = {
  showOnlyHits: CAMPAIGN_SHOW_HITS_FILTER.disabled.value,
}

const initState = {
  isAllCampaignsLoading: false,
  isSingleCamapignLoading: false,
  campaignData: {
    campaignArray: [],
    trackNow: 0,
    trackMax: 0,
  },
  newCampaignData: {
    campaignArray: [],
  },
  currentOpenedCampaign: {
    campaignId: '',
    created: null,
    name: '',
    description: '',
    hashtags: [],
    mentions: [],
    users: [],
    dashboards: [],
    count: 0,
    storyCTA: '',
    sorting: {
      ...defaultSorting,
    },
    filters: {
      ...defaultFilters,
    },
    isLoading: false,
    startingDate: null,
  },
  dashboard: {
    isLoading: true,
    currentDashboardId: '',
    data: [],
    error: null,
    isRunningFetch: false,
    isEmpty: {},
  },
  creatorList: {
    isLoading: true,
    data: [],
    error: null,
  },
  contentList: {
    isLoading: true,
    isShowMoreLoading: false,
    data: [],
    error: null,
    nonRecognizedContent: {
      isLoading: false,
      dataSuccess: false,
    },
    fromBitlyLink: {}
  },
  calendar: {
    isLoading: true,
    error: null,
    events: [],
  },
  goals: {
    isLoading: true,
    error: null,
    data: [],
  },
  trackedMetrics: {
    isLoading: true,
    error: null,
    data: [],
  },
  customFields: {
    isLoading: true,
    error: null,
    data: [],
  },
  pipeline: {
    isLoading: false,
    isLoadingCardData: false,
    isOutOfCampaignsSeats: false,
    error: null,
    board: {
      cards: {},
      columns: {},
      columnOrder: [],
    },
  },
  isLoading: false,
  createCampaign: {
    topCreators: [],
    topHashtags: [],
    statusCode: null,
    newCopyCampaignId: '',
  },
}

// reducer
export const campaignsReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case CHANGE_CAMPAIGNS_LOADING_STATUS:
      return { ...state, isAllCampaignsLoading: !state.isAllCampaignsLoading }
    case CHANGE_SINGLE_CAMPAIGN_LOADING_STATUS:
      return { ...state, isSingleCamapignLoading: !state.isSingleCamapignLoading }
    case SET_ALL_CAMPAIGNS:
      return { ...state, campaignData: action.payload }
    case SET_CURRENT_OPENED_CAMPAIGN:
      return {
        ...state,
        currentOpenedCampaign: { ...state.currentOpenedCampaign, ...action.payload },
      }
    case CHANGE_USERS_IN_OPENED_CAMPAIGN:
      return {
        ...state,
        currentOpenedCampaign: { ...state.currentOpenedCampaign, users: action.payload },
      }
    case CHANGE_OPEN_CAMPAIGN_SORTING:
      return {
        ...state,
        currentOpenedCampaign: {
          ...state.currentOpenedCampaign,
          sorting: { ...state.currentOpenedCampaign.sorting, ...action.payload },
        },
      }
    case CHANGE_OPEN_CAMPAIGN_FILTERS:
      return {
        ...state,
        currentOpenedCampaign: {
          ...state.currentOpenedCampaign,
          filters: { ...state.currentOpenedCampaign.filters, ...action.payload },
        },
      }
    case CHANGE_CURRENT_CAMPAIGN_LOADING:
      return {
        ...state,
        currentOpenedCampaign: {
          ...state.currentOpenedCampaign,
          isLoading: action.payload,
        },
      }

    case CHANGE_NEW_CAMPAIGN_DATA:
      return {
        ...state,
        newCampaignData: {
          ...state.newCampaignData,
          campaignArray: action.payload,
        },
      }

    case CHANGE_NEW_CAMPAIGNS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    /* Dashboard */
    case CHANGE_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: action.payload,
        },
      }
    case CHANGE_DASHBOARD_ID:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          currentDashboardId: action.payload,
        },
      }
    case CHANGE_DASHBOARD_LOADING:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isLoading: action.payload,
        },
      }
    case CHANGE_DASHBOARD_ERROR:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          error: action.payload,
        },
      }
    case CHANGE_DASHBOARD_IS_RUNNING_FETCH:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isRunningFetch: action.payload,
        },
      }
    case CHANGE_IS_EMPTY_VARIABLES:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isEmpty: { ...action.payload },
        },
      }
    /* Content Overview */
    case CHANGE_CONTENT_LIST_SHOW_MORE_BTN_LOADING:
      return {
        ...state,
        contentList: {
          ...state.contentList,
          isShowMoreLoading: action.payload,
        },
      }
    case CHANGE_CONTENT_LIST_LOADING:
      return {
        ...state,
        contentList: {
          ...state.contentList,
          isLoading: action.payload,
        },
      }
    case CHANGE_CONTENT_LIST_DATA:
      return {
        ...state,
        contentList: {
          ...state.contentList,
          data: action.payload,
        },
      }
    case CHANGE_CONTENT_LIST_ERROR:
      return {
        ...state,
        contentList: {
          ...state.contentList,
          error: action.payload,
        },
      }
    case CHANGE_CONTENT_LIST_NON_RECOGNIZED_LOADING:
      return {
        ...state,
        contentList: {
          ...state.contentList,
          nonRecognizedContent: {
            ...state.contentList.nonRecognizedContent,
            isLoading: action.payload,
          },
        },
      }
    case CHANGE_CONTENT_LIST_NON_RECOGNIZED_DATA_SUCCESS:
      return {
        ...state,
        contentList: {
          ...state.contentList,
          nonRecognizedContent: {
            ...state.contentList.nonRecognizedContent,
            dataSuccess: action.payload,
          },
        },
      }
    case CHANGE_CONTENT_LIST_BY_BITLY_LINK:
      return {
        ...state,
        contentList: {
          ...state.contentList,
          fromBitlyLink: {
            ...state.contentList.fromBitlyLink,
            ...action.payload
          },
        },
      }

    /* Creator Overview */
    case CHANGE_CREATOR_DATA:
      return {
        ...state,
        creatorList: {
          ...state.creatorList,
          data: action.payload,
        },
      }
    case CHANGE_CREATOR_LOADING:
      return {
        ...state,
        creatorList: {
          ...state.creatorList,
          isLoading: action.payload,
        },
      }
    case CHANGE_CREATOR_ERROR:
      return {
        ...state,
        creatorList: {
          ...state.creatorList,
          error: action.payload,
        },
      }

    /* Calendar */
    case CHANGE_CALENDAR_LOADING:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          isLoading: action.payload,
        },
      }
    case CHANGE_CALENDAR_ERROR:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          error: action.payload,
        },
      }
    case CHANGE_CALENDAR_EVENTS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          events: action.payload,
        },
      }

    /* Goals*/
    case CHANGE_GOALS_LOADING:
      return {
        ...state,
        goals: {
          ...state.goals,
          isLoading: action.payload,
        },
      }
    case CHANGE_GOALS_ERROR:
      return {
        ...state,
        goals: {
          ...state.goals,
          error: action.payload,
        },
      }
    case CHANGE_GOALS_DATA:
      return {
        ...state,
        goals: {
          ...state.goals,
          data: action.payload,
        },
      }

    /* Tracked Metrics */
    case CHANGE_TRACKED_METRICS_LOADING:
      return {
        ...state,
        trackedMetrics: {
          ...state.trackedMetrics,
          isLoading: action.payload,
        },
      }
    case CHANGE_TRACKED_METRICS_ERROR:
      return {
        ...state,
        trackedMetrics: {
          ...state.trackedMetrics,
          error: action.payload,
        },
      }
    case CHANGE_TRACKED_METRICS_DATA:
      return {
        ...state,
        trackedMetrics: {
          ...state.trackedMetrics,
          data: action.payload,
        },
      }

    /* Custom Fields */
    case CHANGE_CUSTOM_FIELDS_LOADING:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isLoading: action.payload,
        },
      }
    case CHANGE_CUSTOM_FIELDS_ERROR:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          error: action.payload,
        },
      }
    case CHANGE_CUSTOM_FIELDS_DATA:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          data: action.payload,
        },
      }

    /* Pipeline */
    case CHANGE_PIPELINE_LOADING:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          isLoading: action.payload,
        },
      }
    case CHANGE_PIPELINE_ERROR:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          error: action.payload,
        },
      }
    case CHANGE_PIPELINE_BOARD_CARDS:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          board: {
            ...state.pipeline.board,
            cards: action.payload,
          },
        },
      }
    case CHANGE_PIPELINE_BOARD_COLUMNS:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          board: {
            ...state.pipeline.board,
            columns: action.payload,
          },
        },
      }
    case CHANGE_PIPELINE_BOARD_COLUMNS_ORDER:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          board: {
            ...state.pipeline.board,
            columnOrder: action.payload,
          },
        },
      }
    case CHANGE_PIPELINE_BOARD_CARD_LOADING:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          isLoadingCardData: action.payload,
        },
      }
    case CHANGE_PIPELINE_OUT_CAMPAIGN_SEATS:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          isOutOfCampaignsSeats: action.payload,
        },
      }

    /* Create Campaign */
    case CHANGE_CREATE_CAMPAIGN_TOP_CREATORS:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          topCreators: action.payload,
        },
      }
    case CHANGE_CREATE_CAMPAIGN_TOP_HASHTAGS:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          topHashtags: action.payload,
        },
      }
    case CHANGE_CREATE_CAMPAIGN_STATUS_CODE:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          statusCode: action.payload,
        },
      }

    case CHANGE_NEW_COPY_CAMPAIGN_ID:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          newCopyCampaignId: action.payload,
        },
      }
    case RESET_CAMPAIGNS:
      return { ...initState }

    default:
      return state
  }
}

// actions
export const changeCampaignsLoadingStatus = () => actionProvider(CHANGE_CAMPAIGNS_LOADING_STATUS)
export const changeSingleCampaignLoadingStatus = () =>
  actionProvider(CHANGE_SINGLE_CAMPAIGN_LOADING_STATUS)
export const setAllCampaigns = payload => actionProvider(SET_ALL_CAMPAIGNS, payload)
export const setCurrentOpenedCampaign = payload =>
  actionProvider(SET_CURRENT_OPENED_CAMPAIGN, payload)
export const changeUsersInOpenedCampaign = payload =>
  actionProvider(CHANGE_USERS_IN_OPENED_CAMPAIGN, payload)
export const resetCampaigns = () => actionProvider(RESET_CAMPAIGNS)
export const changeOpenedCampaignSorting = payload =>
  actionProvider(CHANGE_OPEN_CAMPAIGN_SORTING, payload)
export const changeOpenedCampaignFilters = payload =>
  actionProvider(CHANGE_OPEN_CAMPAIGN_FILTERS, payload)
export const changeNewCampaignData = payload => actionProvider(CHANGE_NEW_CAMPAIGN_DATA, payload)
export const changeNewCampaignIsLoading = payload =>
  actionProvider(CHANGE_NEW_CAMPAIGNS_LOADING, payload)
export const changeLoadingCurrentCampaign = payload =>
  actionProvider(CHANGE_CURRENT_CAMPAIGN_LOADING, payload)

/* Dashboard */
export const changeDashboardId = payload => actionProvider(CHANGE_DASHBOARD_ID, payload)
export const changeDataDashboard = payload => actionProvider(CHANGE_DASHBOARD_DATA, payload)
export const changeLoadingDashboard = payload => actionProvider(CHANGE_DASHBOARD_LOADING, payload)
export const changeErrorDashboard = payload => actionProvider(CHANGE_DASHBOARD_ERROR, payload)
export const changeRunningFetchDashboard = payload =>
  actionProvider(CHANGE_DASHBOARD_IS_RUNNING_FETCH, payload)
export const changeDashboardIsEmptyVariables = payload =>
  actionProvider(CHANGE_IS_EMPTY_VARIABLES, payload)

/* Content Overview */
export const changeContentListData = payload => actionProvider(CHANGE_CONTENT_LIST_DATA, payload)
export const changeContentListLoading = payload =>
  actionProvider(CHANGE_CONTENT_LIST_LOADING, payload)
export const changeContentListShowMoreBtnLoading = payload =>
  actionProvider(CHANGE_CONTENT_LIST_SHOW_MORE_BTN_LOADING, payload)
export const changeContentListError = payload => actionProvider(CHANGE_CONTENT_LIST_ERROR, payload)
export const changeContentListNonRecognizedLoading = payload =>
  actionProvider(CHANGE_CONTENT_LIST_NON_RECOGNIZED_LOADING, payload)
export const changeContentListNonRecognizedDataSuccess = payload =>
  actionProvider(CHANGE_CONTENT_LIST_NON_RECOGNIZED_DATA_SUCCESS, payload)
export const changeContentByBitlyLink  = payload => actionProvider(CHANGE_CONTENT_LIST_BY_BITLY_LINK, payload)

/* Creator Overview */
export const changeDataCreatorList = payload => actionProvider(CHANGE_CREATOR_DATA, payload)
export const changeLoadingCreatorList = payload => actionProvider(CHANGE_CREATOR_LOADING, payload)
export const changeErrorCreatorList = payload => actionProvider(CHANGE_CREATOR_ERROR, payload)

/* Calendar */
export const changeLoadingCalendar = payload => actionProvider(CHANGE_CALENDAR_LOADING, payload)
export const changeErrorCalendar = payload => actionProvider(CHANGE_CALENDAR_ERROR, payload)
export const changeEventsCalendar = payload => actionProvider(CHANGE_CALENDAR_EVENTS, payload)

/* Goals */
export const changeLoadingGoals = payload => actionProvider(CHANGE_GOALS_LOADING, payload)
export const changeErrorGoals = payload => actionProvider(CHANGE_GOALS_ERROR, payload)
export const changeDataGoals = payload => actionProvider(CHANGE_GOALS_DATA, payload)

/* Tracked Metrics */
export const changeLoadingTrackedMetrics = payload =>
  actionProvider(CHANGE_TRACKED_METRICS_LOADING, payload)
export const changeErrorTrackedMetrics = payload =>
  actionProvider(CHANGE_TRACKED_METRICS_ERROR, payload)
export const changeDataTrackedMetrics = payload =>
  actionProvider(CHANGE_TRACKED_METRICS_DATA, payload)

/* Custom Fields */
export const changeLoadingCustomFields = payload =>
  actionProvider(CHANGE_CUSTOM_FIELDS_LOADING, payload)
export const changeErrorCustomFields = payload =>
  actionProvider(CHANGE_CUSTOM_FIELDS_ERROR, payload)
export const changeDataCustomFields = payload => actionProvider(CHANGE_CUSTOM_FIELDS_DATA, payload)

/* Pipeline */
export const changeLoadingPipeline = payload => actionProvider(CHANGE_PIPELINE_LOADING, payload)
export const changeErrorPipeline = payload => actionProvider(CHANGE_PIPELINE_ERROR, payload)
export const changeBoardCardsPipeline = payload =>
  actionProvider(CHANGE_PIPELINE_BOARD_CARDS, payload)
export const changeBoardColumnsPipeline = payload =>
  actionProvider(CHANGE_PIPELINE_BOARD_COLUMNS, payload)
export const changeBoardColumnsOrderPipeline = payload =>
  actionProvider(CHANGE_PIPELINE_BOARD_COLUMNS_ORDER, payload)
export const changeBoardCardLoadingPipeline = payload =>
  actionProvider(CHANGE_PIPELINE_BOARD_CARD_LOADING, payload)
export const changeOutCampaignSeats = payload =>
  actionProvider(CHANGE_PIPELINE_OUT_CAMPAIGN_SEATS, payload)

/* Create Campaign */
export const changeCampaignTopCreators = payload =>
  actionProvider(CHANGE_CREATE_CAMPAIGN_TOP_CREATORS, payload)
export const changeCampaignTopHashtags = payload =>
  actionProvider(CHANGE_CREATE_CAMPAIGN_TOP_HASHTAGS, payload)
export const changeCreateCampaignStatusCode = payload =>
  actionProvider(CHANGE_CREATE_CAMPAIGN_STATUS_CODE, payload)
export const changeNewCopyCampaignId = payload =>
  actionProvider(CHANGE_NEW_COPY_CAMPAIGN_ID, payload)

export const resetDataForCampaignPages = () => async dispatch => {
  dispatch([
    setCurrentOpenedCampaign(initState.currentOpenedCampaign),
    changeDataDashboard([]),
    changeDataCreatorList([]),
    changeDataTrackedMetrics([]),
    changeDataGoals([]),
    changeEventsCalendar([]),
    changeDataCustomFields([]),
    changeBoardCardsPipeline({}),
    changeBoardColumnsOrderPipeline([]),
    changeBoardColumnsPipeline({}),
  ])
}

// async actions
export const createCampaign = ({ name, desc, mentions, hashtags, storyCTA }) => async dispatch => {
  if (!name) return
  try {
    dispatch(changeCampaignsLoadingStatus())
    await httpService.fetchCreateCampaign({
      campaignName: name,
      description: desc,
      hashtags,
      mentions,
      storyCTA,
    })
    dispatch(fetchAllCampaigns())
    dispatch(changeCampaignsLoadingStatus())
  } catch (err) {
    dispatch([setError(ERROR_MSG.failCreateCampaign), changeCampaignsLoadingStatus()])
  }
}

export const fetchAllCampaigns = () => async dispatch => {
  try {
    const allCampaigns = await httpService.getAllNewCampaigns()
    dispatch(setAllCampaigns(allCampaigns))
  } catch (err) {
    dispatch(setError(ERROR_MSG.failAllCampaign))
  }
}

export const fetchCampaign = (campaignId = '') => async (dispatch, getState) => {
  if (!campaignId) return
  dispatch([
    setCurrentOpenedCampaign(initState.currentOpenedCampaign),
    changeLoadingCurrentCampaign(true),
  ])
  try {
    const { campaignId: currentCampaignId } = currentOpenedCampaignSelector(getState())
    const isSameCampaign = currentCampaignId === campaignId
    if (!isSameCampaign) {
      dispatch([setCampaignInitSorting(campaignId), setCamapaignInitFilters(campaignId)])
    }

    const isCampaignAlreadyLoading = isSingleCampaignLoadingSelector(getState())
    !isCampaignAlreadyLoading && dispatch(changeSingleCampaignLoadingStatus())
    const newCampaign = await httpService.getNewCampaignById({ campaignId })
    dispatch([
      !Array.isArray(newCampaign) && // check if nothing failed on backend and we get data object
        newCampaign.campaignId &&
        setCurrentOpenedCampaign({
          ...newCampaign,
          users: dispatch(_sortUsersInOpenedCampaign({ users: newCampaign.users })),
        }),
      changeSingleCampaignLoadingStatus(),
      changeLoadingCurrentCampaign(false),
    ])
  } catch (err) {
    dispatch([
      setError(ERROR_MSG.failOneCampaign),
      changeSingleCampaignLoadingStatus(),
      changeLoadingCurrentCampaign(false),
    ])
  }
}

export const fetchDeleteCampaign = campaignId => async (dispatch, getState) => {
  if (!campaignId) return
  try {
    dispatch(changeSingleCampaignLoadingStatus())
    await httpService.fetchDeleteCampaign(campaignId)
    const { campaignArray, trackNow, trackMax } = allCampaignsSelector(getState())
    const currentCampaign = currentOpenedCampaignSelector(getState())
    const trackedUsersCountToRemove = currentCampaign.users.length
    const newCampaignsList = campaignArray.filter(c => c.campaignId !== currentCampaign.campaignId)
    const newTrackNowValue = trackNow - trackedUsersCountToRemove
    dispatch([
      setAllCampaigns({
        campaignArray: newCampaignsList,
        trackNow: newTrackNowValue,
        trackMax,
      }),
      setCurrentOpenedCampaign({ ...initState.currentOpenedCampaign }),
      changeSingleCampaignLoadingStatus(),
    ])
    localStorageService.removeCampaignRelatedData(campaignId)
  } catch (err) {
    dispatch([
      setError(ERROR_MSG.failDeleteCampaign),
      dispatch(changeSingleCampaignLoadingStatus()),
    ])
  }
}

export const fetchUpdateCampaign = ({
  name: newValue,
  desc: description,
  mentions,
  hashtags,
  storyCTA,
}) => async (dispatch, getState) => {
  if (!newValue) return
  try {
    const currentCampaign = currentOpenedCampaignSelector(getState())
    dispatch(changeSingleCampaignLoadingStatus())
    await httpService.fetchEditCampaign({
      campaignId: currentCampaign.campaignId,
      newValue,
      description,
      mentions,
      hashtags,
      storyCTA,
    })
    const updatedCurrentCampaign = {
      name: newValue,
      description,
      mentions,
      hashtags,
      storyCTA,
    }
    // const latestCampaign = await fetchCampaign()
    const { campaignArray, ...restOfAllCamapigns } = allCampaignsSelector(getState())
    const updatedCampaignArray = campaignArray.map(c => {
      return c.campaignId === currentCampaign.campaignId ? { ...c, ...updatedCurrentCampaign } : c
    })
    dispatch([
      setAllCampaigns({ campaignArray: updatedCampaignArray, ...restOfAllCamapigns }),
      // setCurrentOpenedCampaign({ ...currentCampaign, ...updatedCurrentCampaign }),
      fetchCampaign(currentCampaign.campaignId),
      // changeSingleCampaignLoadingStatus(),
    ])
  } catch (err) {
    dispatch([setError(ERROR_MSG.failChangesCampaign), changeSingleCampaignLoadingStatus()])
  }
}

export const addElementToCampaign = ({ campaignId, ids = [] }) => async (dispatch, getState) => {
  const objectIds = typeof ids === 'string' ? [ids] : ids // must be array of ids
  if (!objectIds.length || !campaignId) return
  const currentOpenedCampaign = currentOpenedCampaignSelector(getState())
  const withFetchCampaign = currentOpenedCampaign.campaignId === campaignId
  try {
    dispatch(changeSingleCampaignLoadingStatus())
    await httpService.addCreatorToCampaign({ campaignId, creatorId: objectIds[0] })
    dispatch([
      withFetchCampaign && fetchCampaign(campaignId),
      modifyElementsInCampaignsEverywhere({ campaignId, objectIds }),
    ])
  } catch (err) {
    dispatch([setError(ERROR_MSG.failChangesCampaign), changeSingleCampaignLoadingStatus()])
  }
}

export const deleteElementFromCampaign = ({ campaignId, ids = [] }) => async dispatch => {
  const objectIds = typeof ids === 'string' ? [ids] : ids // must be array of ids
  if (!objectIds.length || !campaignId) return
  try {
    dispatch(changeSingleCampaignLoadingStatus())
    await httpService.removeElementFromCampaign({ campaignId, objectIds })

    dispatch([
      modifyElementsInCampaignsEverywhere({ campaignId, objectIds, isDelete: true }),
      changeSingleCampaignLoadingStatus(),
    ])
  } catch (err) {
    dispatch([setError(ERROR_MSG.failChangesCampaign), changeSingleCampaignLoadingStatus()])
  }
}

export const modifyUserInCampaign = ({ userId, fieldToChange, value }) => async dispatch => {
  if (!userId || !fieldToChange) return
  dispatch(
    modifyProfileElementEverywhere({
      profileId: userId,
      changeField: fieldToChange,
      newValue: value,
    })
  )
}

export const setCampaignInitSorting = campaignId => dispatch => {
  if (!campaignId) return
  const sortTypeFromStorage = localStorageService.getCampaignSorting(campaignId)
  if (sortTypeFromStorage === null) {
    dispatch(changeOpenedCampaignSorting({ ...defaultSorting }))
  } else {
    const { sortType, sortDirection } = sortTypeFromStorage

    const sortKey = Object.keys(CAMPAIGNS_SORTING_TYPES).find(
      k => CAMPAIGNS_SORTING_TYPES[k].type === sortType
    )
    if (sortKey) {
      dispatch(
        changeOpenedCampaignSorting({
          sortKey,
          sortDirection,
        })
      )
    }
  }
}

export const changeCampaignUsersSorting = ({
  sortKey: newSortKey = '',
  sortDirection: newSortDirection = '',
}) => (dispatch, getState) => {
  const { sortKey, sortDirection } = currentCampaignSortingSelector(getState())
  const { campaignId } = currentOpenedCampaignSelector(getState())
  const sortKeyToSet = newSortKey || sortKey
  const sortDirectionToSet = newSortDirection || sortDirection
  dispatch([
    changeOpenedCampaignSorting({
      sortKey: sortKeyToSet,
      sortDirection: sortDirectionToSet,
    }),
    _sortUsersInOpenedCampaign({ withDispatch: true }),
  ])
  localStorageService.setCampaignSorting(
    campaignId,
    CAMPAIGNS_SORTING_TYPES[sortKeyToSet].type,
    sortDirectionToSet
  )
}

export const changeCampaignShowOnlyHitsFilter = showOnlyHitsName => (dispatch, getState) => {
  const { campaignId, filters: currentFilters } = currentOpenedCampaignSelector(getState())
  dispatch(
    changeOpenedCampaignFilters({ showOnlyHits: CAMPAIGN_SHOW_HITS_FILTER[showOnlyHitsName].value })
  )
  localStorageService.setCampaignFilters(campaignId, {
    ...currentFilters,
    showOnlyHits: CAMPAIGN_SHOW_HITS_FILTER[showOnlyHitsName].name,
  })
}

export const setCamapaignInitFilters = campaignId => dispatch => {
  if (!campaignId) return
  const filters = localStorageService.getCampaignFilters(campaignId)
  if (filters) {
    // in future more filters can be added here
    const { showOnlyHits: showHitsName } = filters
    dispatch(
      changeOpenedCampaignFilters({ showOnlyHits: CAMPAIGN_SHOW_HITS_FILTER[showHitsName].value })
    )
  } else {
    dispatch(changeOpenedCampaignFilters({ ...defaultFilters }))
  }
}

// private sort func
const _sortUsersInOpenedCampaign = ({ users = null, withDispatch = false }) => (
  dispatch,
  getState
) => {
  if (!users) users = currentOpenedCampaignUsersSelector(getState())
  if (!users.length) return users
  const { sortKey, sortDirection } = currentCampaignSortingSelector(getState())
  const sortedUsers = sortArrayOfObjects({
    array: users,
    sortKey,
    sortDirection,
  })
  return withDispatch ? dispatch(changeUsersInOpenedCampaign(sortedUsers)) : sortedUsers
}

export const changeCampaignSeats = ({ newSeats }) => async (dispatch, getState) => {
  const { subId } = userSubSelector(getState())
  const refreshGrant = refreshGrantSelector(getState())
  try {
    dispatch(toggleUserCredsLoad())
    await httpService.changeSeatsOfTracking({ subId, newSeats })
    await refreshGrant()
    dispatch([toggleUserCredsLoad(), fetchAllCampaigns()])
    return true
  } catch (err) {
    const isUserCredsAreLoading = userCredsLoadProgressSelector(getState())
    dispatch([
      isUserCredsAreLoading && toggleUserCredsLoad(), // if error, turn off loading status
      setError(ERROR_MSG.failUpdatecampaignSeats),
    ])
    return false
  }
}

export const getCampaignDashboardData = ({ campaignId, dashboardId }) => async (
  dispatch,
  getState
) => {
  const { campaignDashboard: dashboardDataFromState, isRunningFetch } = campaignDashboard(
    getState()
  )
  try {
    if (isRunningFetch) {
      changeLoadingDashboard(true)
      throw new Error('')
    }

    dispatch([changeLoadingDashboard(true), changeRunningFetchDashboard(true)])

    if (dashboardDataFromState?.length) {
      dispatch(changeDataDashboard([]))
    }

    const dashboardData = await httpService.getDataCampaignDashboard({
      campaignId,
      dashboardId,
    })
    const { data, isEmptyCampaign, isEmptyDashboard } = dashboardData || {}

    dispatch([
      changeDashboardId(dashboardId),
      changeDataDashboard(data),
      changeDashboardIsEmptyVariables({ isEmptyCampaign, isEmptyDashboard }),
      changeLoadingDashboard(false),
      changeRunningFetchDashboard(false),
    ])
  } catch (err) {
    const { response, code } = err || {}

    if (code === 'ERR_CANCELED') {
      dispatch([changeLoadingDashboard(true), changeErrorDashboard(response?.data)])
    } else {
      dispatch([
        !isRunningFetch && changeLoadingDashboard(false),
        changeErrorDashboard(response?.data),
        changeRunningFetchDashboard(false),
      ])
    }
  }
}

export const getPreviewCampaignDashboardData = ({
  campaignId,
  dashboardId,
  dashboardToken,
}) => async dispatch => {
  try {
    dispatch([changeDataDashboard([]), changeLoadingDashboard(true)])

    const dashboardData = await httpService.getPreviewDataCampaignDashboard({
      campaignId,
      dashboardId,
      dashboardToken,
    })

    const { data, isEmptyCampaign, isEmptyDashboard } = dashboardData || {}

    dispatch([
      changeDashboardId(dashboardId),
      changeDataDashboard(data),
      changeDashboardIsEmptyVariables({ isEmptyCampaign, isEmptyDashboard }),
      changeLoadingDashboard(false),
    ])
  } catch (err) {
    dispatch([changeLoadingDashboard(false), changeErrorDashboard(err)])
  }
}

export const getAllNewCampaigns = ({ campaignId }) => async (dispatch, getState) => {
  const campaignsList = getAllCampaignsSelector(getState())
  try {
    if (!Object.keys(campaignsList)?.length) {
      const campaignsData = await httpService.getAllNewCampaigns()
      dispatch(changeNewCampaignData(campaignsData))

      if (campaignsData?.length) {
        const findCampaignId = campaignsData?.find(el => el.campaignId === campaignId)?.campaignId
        const getCampId = findCampaignId ? findCampaignId : campaignsData[0]?.campaignId

        if (getCampId) {
          dispatch(fetchCampaign(getCampId))
        }
      } else {
        dispatch([changeNewCampaignIsLoading(false), changeLoadingDashboard(false)])
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export const onlyFetchNewCampaigns = () => async (dispatch, getState) => {
  try {
    const campaignsData = await httpService.getAllNewCampaigns()

    if (campaignsData) {
      dispatch(changeNewCampaignData(campaignsData))
    }
  } catch (err) {
    console.error(err)
  }
}

const switchToAvailableCampaign = ({ history }) => async dispatch => {
  try {
    const newCampaignsData = await httpService.getAllNewCampaigns()

    if (newCampaignsData) {
      dispatch(changeNewCampaignData(newCampaignsData))
      const firstCampaignId = newCampaignsData[0]?.campaignId

      if (firstCampaignId) {
        dispatch(
          changeCampaign({
            campaignId: firstCampaignId,
            history,
          })
        )
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export const editDashboard = ({
  selectedCreators,
  startingDate,
  endingDate,
  campaignId,
  dashboardId,
  visibleGraphElements,
  method,
  withRefetch,
  history,
  isShared,
}) => async dispatch => {
  if (withRefetch) {
    dispatch([
      changeNewCampaignIsLoading(true),
      changeDataDashboard([]),
      changeLoadingDashboard(true),
      changeLoadingCurrentCampaign(true),
    ])
  }

  try {
    const editDash = await httpService.editDashboards({
      selectedCreators,
      startingDate: startingDate ? format(new Date(startingDate), dateFormatMaskYYYY_MM_dd) : '',
      endingDate: endingDate ? format(new Date(endingDate), dateFormatMaskYYYY_MM_dd) : '',
      visibleGraphElements,
      campaignId,
      dashboardId,
      method,
      isShared,
    })

    if (withRefetch && editDash) {
      dispatch([
        changeDashboardId(dashboardId),
        fetchCampaign(campaignId),
        history && history.replace(`/campaigns/${campaignId}/dashboard?dashboardId=${dashboardId}`),
      ])
    }
  } catch (err) {
    console.error(err)
  }
}

export const changeCampaign = ({ campaignId, history, isCopyCampaign }) => async (
  dispatch,
  getState
) => {
  dispatch(resetDataForCampaignPages())

  try {
    const { campaignId: currentCampaignId } = currentOpenedCampaignSelector(getState())
    const isSameCampaign = currentCampaignId === campaignId
    if (!isSameCampaign) {
      dispatch([setCampaignInitSorting(campaignId), setCamapaignInitFilters(campaignId)])
    }

    const isCampaignAlreadyLoading = isSingleCampaignLoadingSelector(getState())
    !isCampaignAlreadyLoading && dispatch(changeSingleCampaignLoadingStatus())
    const newCampaign = await httpService.getNewCampaignById({ campaignId })
    dispatch([
      !Array.isArray(newCampaign) &&
        newCampaign.campaignId &&
        setCurrentOpenedCampaign({
          ...newCampaign,
          users: dispatch(_sortUsersInOpenedCampaign({ users: newCampaign.users })),
        }),
      changeSingleCampaignLoadingStatus(),
      changeNewCampaignIsLoading(false),
    ])

    const dashboardId = newCampaign?.dashboards[0]?.dashboardId

    if (dashboardId && campaignId) {
      history.replace(`/campaigns/${campaignId}/dashboard?dashboardId=${dashboardId}`)
      !isCopyCampaign &&
        dispatch([
          changeDataDashboard([]),
          changeContentListLoading(true),
          changeContentListData([]),
        ])
    }
  } catch (e) {
    console.error(e)
  }
}

export const getCampaignContentList = ({
  campaignId,
  page,
  startingDate,
  endingDate,
  selectedCreators,
  filterContentTypes,
}) => async (dispatch, getState) => {
  const {
    data: { contentArray },
  } = contentListSelector(getState())
  dispatch(changeContentListLoading(true))
  try {
    if (contentArray?.length) {
      dispatch(changeContentListShowMoreBtnLoading(true))
    }

    const contentListData = await httpService.getContentList({
      campaignId,
      page,
      startingDate,
      endingDate,
      selectedCreators: Array.isArray(selectedCreators)
        ? selectedCreators.join(',')
        : selectedCreators,
      filterContentTypes: Array.isArray(filterContentTypes)
        ? filterContentTypes.join(',')
        : filterContentTypes,
    })

    dispatch([
      changeContentListLoading(false),
      changeContentListShowMoreBtnLoading(false),
      changeContentListData({
        contentArray:
          page === 1
            ? contentListData.contentArray
            : contentArray?.concat(contentListData.contentArray),
        totalContentPieces: contentListData?.totalContentPieces,
        hasMoreItems: contentListData?.hasMoreItems
      }),
    ])
  } catch (err) {
    if (err.message === 'canceled') {
      dispatch(changeContentListError(err))
    } else {
      dispatch([changeContentListError(err), changeContentListLoading(false)])
    }
  }
}

export const editCampaignContent = payload => async dispatch => {
  const { startingDate, endingDate, ...other } = payload
  dispatch([changeContentListData([]), changeContentListLoading(true)])
  try {
    const editContent = await httpService.editContent(other)

    if (editContent) {
      dispatch(
        // @ts-ignore
        getCampaignContentList({
          campaignId: payload.campaignId,
          page: 1,
          startingDate,
          endingDate,
        })
      )
    } else {
      changeContentListLoading(false)
    }
  } catch (err) {
    if (err.message === 'canceled') {
      dispatch(changeContentListError(err))
    } else {
      dispatch([changeContentListError(err), changeContentListLoading(false)])
    }
  }
}

export const removeContentFromTracking = obj => async dispatch => {
  const { contentId, campaignId, page, startingDate, endingDate, selectedCreators } = obj || {}
  dispatch([changeContentListLoading(true), changeContentListData([])])
  try {
    await httpService.removeContentFromTracking({ contentId, campaignId })

    dispatch(
      getCampaignContentList({
        campaignId,
        page,
        startingDate,
        endingDate,
        selectedCreators,
      })
    )
  } catch (err) {
    dispatch([changeContentListLoading(false), changeContentListError(err)])
  }
}

export const getCampaignCreatorList = ({ campaignId }) => async dispatch => {
  dispatch(changeLoadingCreatorList(true))
  try {
    const creatorListData = await httpService.getCreatorList({
      campaignId,
    })

    if (creatorListData?.length) {
      dispatch(changeDataCreatorList(creatorListData))
    }

    dispatch(changeLoadingCreatorList(false))
  } catch (err) {
    dispatch([changeErrorCreatorList(err), changeLoadingCreatorList(false)])
  } finally {
    dispatch(changeLoadingCreatorList(false))
  }
}

export const addCreatorToCampaign = ({
  creatorId,
  campaignId,
  defaultAxiosFetch,
}) => async dispatch => {
  try {
    const addCreatorToCampaign = await httpService.addCreatorToCampaign({
      creatorId,
      campaignId,
      defaultAxiosFetch,
    })

    const { status, data } = addCreatorToCampaign || {}

    if (status === 202 && defaultAxiosFetch) {
      toast(<ToastAddCreatorToCampaign />, { autoClose: false, closeButton: true })
    }

    if (data) {
      dispatch([
        changeCampaignLimitTrackNow({ type: 'incr' }),
        getCampaignCreatorList({ campaignId }),
      ])
    }
  } catch (err) {
    dispatch(changeErrorCreatorList(err))
  }
}

export const changeCreator = ({
  creatorId,
  campaignId,
  matchMethod,
  isDeleted,
  isBrandAccount,
  pricing,
  isArchived,
  isOnboarding,
  shouldContactCreator,
  creatorContactMethods,
  overwriteAudience,
  manualAudienceReport,
  individualCustomFields,
  customCreatorGoals,
  overwriteName,
  bitlyLink,
  enablePricing,
  withPipeline,
  withoutRefetchCreatorListData,
}) => async dispatch => {
  !withoutRefetchCreatorListData && dispatch(changeLoadingCreatorList(true))
  try {
    const changeCreatorRequest = await httpService.changeCreator({
      creatorId,
      campaignId,
      fieldsToChange: {
        isDeleted,
        isBrandAccount,
        pricing,
        isArchived,
        isOnboarding,
        shouldContactCreator,
        creatorContactMethods,
        overwriteAudience,
        manualAudienceReport,
        individualCustomFields,
        customCreatorGoals,
        overwriteName,
        bitlyLink,
        enablePricing,
        matchMethod,
      },
    })

    if (changeCreatorRequest && !withPipeline) {
      if (isArchived) {
        dispatch(changeCampaignLimitTrackNow({ type: 'decr' }))
      }

      !withoutRefetchCreatorListData && dispatch(getCampaignCreatorList({ campaignId }))
    }

    if (withPipeline) {
      if (!isOnboarding && changeCreatorRequest) {
        dispatch(changeCampaignLimitTrackNow({ type: 'incr' }))
      }

      dispatch(
        getPipelineData({
          campaignId: campaignId,
          disablePreloader: true,
        })
      )
    }
  } catch (err) {
    const resStatus = err?.response?.status
    if (resStatus === 403) {
      dispatch([changeOutCampaignSeats(true), setError(ERROR_MSG.outCampaignSeats)])
    }

    dispatch([changeErrorCreatorList(err), changeLoadingCreatorList(false)])
  } finally {
    dispatch([changeBoardCardLoadingPipeline(false)])
  }
}

export const getCalendarEvents = ({ campaignId }) => async dispatch => {
  dispatch(changeLoadingCalendar(true))
  try {
    const events = await httpService.getCalendarData({ campaignId })
    if (events.length) {
      dispatch(changeEventsCalendar(events))
    }
    dispatch(changeLoadingCalendar(false))
  } catch (err) {
    dispatch([changeLoadingCalendar(false), changeErrorCalendar(err)])
  }
}

export const createCalendarEvent = newEvent => async (dispatch, getState) => {
  const { campaignId } = currentOpenedCampaignSelector(getState())
  dispatch(changeLoadingCalendar(true))

  try {
    const createNewEvent = await httpService.addEditCalendarData({
      method: 'add',
      campaignId,
      calendarEntry: newEvent,
    })

    if (createNewEvent) {
      toast.success(`Create success!`, { theme: 'colored' })
      dispatch(getCalendarEvents({ campaignId }))
    }
  } catch (err) {
    dispatch([changeLoadingCalendar(false), changeErrorCalendar(err)])
  }
}

export const updateCalendarEvent = ({ eventId, event }) => async (dispatch, getState) => {
  const { campaignId } = currentOpenedCampaignSelector(getState())
  dispatch(changeLoadingCalendar(true))
  try {
    const updateEvent = await httpService.addEditCalendarData({
      method: 'change',
      campaignId,
      itemId: eventId,
      calendarEntry: event,
    })

    if (updateEvent) {
      toast.success(`Update success!`, { theme: 'colored' })
      dispatch(getCalendarEvents({ campaignId }))
    }
  } catch (err) {
    dispatch([changeLoadingCalendar(false), changeErrorCalendar(err)])
  }
}

export const deleteCalendarEvent = eventId => async (dispatch, getState) => {
  const { campaignId } = currentOpenedCampaignSelector(getState())
  dispatch(changeLoadingCalendar(true))
  try {
    const deleteEvent = await httpService.addEditCalendarData({
      method: 'remove',
      campaignId,
      itemId: eventId,
    })

    if (deleteEvent) {
      toast.success(`Delete success!`, { theme: 'colored' })
      dispatch(getCalendarEvents({ campaignId }))
    }
  } catch (err) {
    dispatch([changeLoadingCalendar(false), changeErrorCalendar(err)])
  }
}

export const getCampaignsGoals = ({ campaignId }) => async dispatch => {
  dispatch(changeLoadingGoals(true))
  try {
    const goals = await httpService.getCampaignGoals({ campaignId })

    dispatch([changeLoadingGoals(false), changeDataGoals(goals)])
  } catch (err) {
    dispatch([changeLoadingGoals(false), changeErrorGoals(err)])
  }
}

export const addChangeRemoveCampaignGoals = ({
  campaignId,
  method,
  goalId,
  goalName,
  connectedToFieldId,
  goalAmount,
  isPaused,
  goalLevel,
  goalInterval,
  isCreatorIndividual,
}) => async dispatch => {
  dispatch(changeLoadingGoals(true))

  try {
    const data = await httpService.addChangeRemoveCampaignGoals({
      campaignId,
      method,
      goalId,
      goalName,
      connectedToFieldId,
      goalAmount,
      isPaused,
      goalLevel,
      goalInterval,
      isCreatorIndividual,
    })

    if (data) {
      dispatch(getCampaignsGoals({ campaignId }))
    } else {
      dispatch(changeLoadingCalendar(false))
    }
  } catch (err) {
    dispatch([changeLoadingGoals(false), changeErrorGoals(err)])
  }
}

export const createNewCampaign = ({ fields, history, goToNextStep }) => async dispatch => {
  dispatch(changeNewCampaignIsLoading(true))
  try {
    const payload = {
      campaignFields: {
        campaignName: fields.campaignName || '',
        trackedFormats: fields?.trackedFormats || [],
        startAsInactive: fields.startAsInactive,
        brandAccountIncludeAll:
          fields.brandAccountIncludeAll === 'null'
            ? null
            : fields.brandAccountIncludeAll === 'false'
            ? false
            : true,
        brandAccountIds: fields.brandAccountIds,
        creatorIds: fields.creatorIds,
        trackedMetrics: fields.trackedMetrics,
        overwriteStartingDate: fields.overwriteStartingDate,
        settings: {
          campaignCurrency: fields?.campaignCurrency || '',
        },
      },
    }

    const res = await httpService.createNewCampaignWithStatusCode(payload)
    const { data, status } = res || {}
    const { campaignId } = data || {}

    if (status) {
      dispatch(changeCreateCampaignStatusCode(status))
    }

    if (campaignId && history && goToNextStep) {
      if (
        fields.startAsInactive === 'false' &&
        fields.brandAccountIncludeAll !== 'null' &&
        !!fields.brandAccountIds?.length
      ) {
        dispatch(changeCampaignLimitTrackNow({ type: 'incr' }))
      }

      goToNextStep()
      dispatch([
        onlyFetchNewCampaigns(),
        changeCampaign({
          campaignId,
          history,
        }),
      ])
    }
  } catch (err) {
    console.error(err)
    dispatch(changeNewCampaignIsLoading(false))
  } finally {
    dispatch(changeNewCampaignIsLoading(false))
  }
}

export const editCampaignSettings = fields => async (dispatch, getState) => {
  const { campaignId } = currentOpenedCampaignSelector(getState())

  dispatch([
    setCurrentOpenedCampaign({
      settings: {},
    }),
    changeSingleCampaignLoadingStatus(),
  ])

  try {
    const payload = {
      campaignId,
      fieldsToChange: {
        campaignName: fields.campaignName || '',
        setAsActive: fields.setAsActive,
        setAsDeleted: fields.setAsDeleted,
        setAsInactive: fields.setAsInactive,
        settings: {
          campaignCurrency: fields?.campaignCurrency || false,
          trackReachOnlyManually: fields?.trackReachOnlyManually || false,
          trackPricing: fields?.trackPricing || false,
          trackSponsoredContent: fields?.trackSponsoredContent || false,
        },
      },
    }

    const editCampaignSettings = await httpService.editCampaignSettings(payload)

    if (editCampaignSettings) {
      if (fields.setAsActive) {
        dispatch(changeCampaignLimitTrackNow({ type: 'incr' }))
      }
      if (fields.setAsDeleted || fields.setAsInactive) {
        dispatch([
          changeCampaignLimitTrackNow({ type: 'decr' }),
          switchToAvailableCampaign({ history: fields.history }),
        ])
      }

      if (!fields.withoutFetchSingleCampaign) {
        dispatch([fetchCampaign(campaignId), changeSingleCampaignLoadingStatus()])
        if (fields.setAsActive) {
          window?.location?.reload()
        }
      }
    }
  } catch (err) {
    dispatch(changeSingleCampaignLoadingStatus())
    console.error(err)
  }
}

export const getTrackedMetrics = ({ campaignId }) => async dispatch => {
  dispatch(changeLoadingTrackedMetrics(true))
  try {
    const trackedMetricsData = await httpService.getTrackedMetrics({ campaignId })

    if (trackedMetricsData?.length) {
      dispatch([changeDataTrackedMetrics(trackedMetricsData), changeLoadingTrackedMetrics(false)])
    }
  } catch (err) {
    dispatch([changeLoadingTrackedMetrics(false), changeErrorTrackedMetrics(err)])
  } finally {
    dispatch(changeLoadingTrackedMetrics(false))
  }
}

export const editTrackedMetrics = ({
  campaignId,
  method,
  metricId,
  value,
  type,
  startingDate,
  isExact,
}) => async dispatch => {
  const payload = {}
  dispatch(changeLoadingTrackedMetrics(true))
  try {
    if (method === 'remove') {
      payload.campaignId = campaignId
      payload.method = method
      payload.metricId = metricId
    }

    if (method !== 'remove') {
      payload.campaignId = campaignId
      payload.method = method
      payload.metricId = method === 'change' ? metricId : null
      payload.type = type
      payload.value = value
      payload.startingDate = startingDate
      payload.isExact = isExact || null
    }

    const editMetricsDataResponse = await httpService.editTrackedMetrics(payload)

    if (editMetricsDataResponse) {
      dispatch(getTrackedMetrics({ campaignId }))
    }
  } catch (err) {
    dispatch([changeLoadingTrackedMetrics(false), changeErrorTrackedMetrics(err)])
  }
}

export const getCustomFields = ({ campaignId }) => async dispatch => {
  dispatch(changeLoadingCustomFields(true))
  try {
    const customFieldsData = await httpService.getCustomFields({ campaignId })
    dispatch([changeDataCustomFields(customFieldsData), changeLoadingCustomFields(false)])
  } catch (err) {
    dispatch([changeLoadingCustomFields(false), changeErrorCustomFields(err)])
  } finally {
    dispatch(changeLoadingCustomFields(false))
  }
}

export const editCustomFields = ({
  campaignId,
  method,
  customFieldId,
  customFieldName,
  metricName,
  dataFormat,
  applyLevel,
  applyContentArray,
  trackingMethod,
  forwardedUrl,
}) => async dispatch => {
  dispatch(changeLoadingCustomFields(true))
  const payload = {}
  try {
    if (method === 'remove') {
      payload.campaignId = campaignId
      payload.method = method
      payload.customFieldId = customFieldId
    }
    if (method !== 'remove') {
      payload.campaignId = campaignId
      payload.method = method
      payload.customFieldId = method === 'change' ? customFieldId : undefined
      payload.customFieldName = customFieldName
      payload.metricName = metricName
      payload.dataFormat = dataFormat
      payload.applyLevel = applyLevel
      payload.trackingMethod = trackingMethod || 'manual'
      payload.applyContentArray = applyContentArray
      payload.forwardedUrl = forwardedUrl
    }

    const editCustomFieldsResponse = await httpService.editCustomFields(payload)

    if (editCustomFieldsResponse) {
      dispatch(getCustomFields({ campaignId }))
    }
  } catch (err) {
    dispatch([changeLoadingCustomFields(false), changeErrorCustomFields(err)])
  }
}

export const addValueManually = data => async dispatch => {
  dispatch(changeLoadingCustomFields(true))
  try {
    const res = await httpService.addCustomFieldValueManually(data)

    if (res) {
      dispatch(getCustomFields({ campaignId: data.campaignId }))
    }
  } catch (err) {
    dispatch([changeLoadingCustomFields(false), changeErrorCustomFields(err)])
  }
}

export const editNotifications = ({
  campaignId,
  method,
  notificationId,
  emails,
  interval,
  dashboardId,
}) => async dispatch => {
  dispatch(
    setCurrentOpenedCampaign({
      ...initState.currentOpenedCampaign,
      notifications: [],
    })
  )
  const payload = {}
  try {
    if (method === 'remove') {
      payload.campaignId = campaignId
      payload.method = method
      payload.notificationId = notificationId
    }
    if (method !== 'remove') {
      payload.campaignId = campaignId
      payload.method = method
      payload.notificationId = method === 'change' ? notificationId : undefined
      payload.emails = emails
      payload.interval = interval
      payload.dashboardId = dashboardId
    }

    dispatch(changeLoadingCurrentCampaign(true))
    const editNotificationsResponse = await httpService.editNotifications(payload)

    if (editNotificationsResponse) {
      dispatch(fetchCampaign(campaignId))
    }
  } catch (err) {
    console.error(err)
    dispatch(changeLoadingCurrentCampaign(false))
  }
}

export const getNonRecognizedContent = ({
  campaignId,
  creatorId,
  page,
  filterContentTypes,
}) => async (dispatch, getState) => {
  const {
    data: { contentArray },
  } = contentListSelector(getState())
  dispatch(changeContentListLoading(true))
  try {
    const res = await httpService.getNonRecognizedContent({
      campaignId,
      creatorId,
      filterContentTypes,
    })

    dispatch([
      changeContentListLoading(false),
      changeContentListShowMoreBtnLoading(false),
      changeContentListData({
        contentArray: page === 1 ? res.contentArray : contentArray?.concat(res.contentArray),
      }),
    ])
  } catch (err) {
    dispatch([changeContentListError(err), changeContentListLoading(false)])
  }
}

export const addContentToRecognizedContent = ({ contentIds, campaignId }) => async dispatch => {
  dispatch([
    changeContentListNonRecognizedLoading(true),
    changeContentListNonRecognizedDataSuccess(false),
  ])
  try {
    const res = await httpService.addContentToRecognizedContent({ contentIds, campaignId })

    dispatch(changeContentListNonRecognizedLoading(false))

    if (res) {
      dispatch(changeContentListNonRecognizedDataSuccess(true))
    }
  } catch (err) {
    dispatch([
      changeContentListNonRecognizedLoading(false),
      changeContentListNonRecognizedDataSuccess(false),
    ])
  }
}

const hasErrorPipeline = err => async dispatch => {
  dispatch([
    changeLoadingPipeline(false),
    changeBoardCardLoadingPipeline(false),
    changeErrorPipeline(err),
  ])
}

export const getBoardSuccessPipeline = board => async dispatch => {
  const cards = keyBy(board.cards, 'cardId')
  const columns = keyBy(board.columns, 'columnId')
  const { columnOrder, disablePreloader } = board

  dispatch([
    changeBoardCardsPipeline(cards),
    changeBoardColumnsPipeline(columns),
    changeBoardColumnsOrderPipeline(columnOrder),
    disablePreloader ? changeBoardCardLoadingPipeline(false) : changeLoadingPipeline(false),
  ])
}

export const persistCardPipeline = column => async dispatch => {
  dispatch(changeBoardColumnsPipeline(column))
}

export const persistColumnPipeline = columnOrder => async dispatch => {
  dispatch(changeBoardColumnsOrderPipeline(columnOrder))
}

export const getPipelineData = ({ campaignId, disablePreloader = false }) => async dispatch => {
  !disablePreloader && dispatch(changeLoadingPipeline(true))
  try {
    const response = await httpService.getPipelineData({ campaignId })
    const { cards, columns } = response || {}

    const defaultColumnsOrder = columns?.map(el => el.columnId)

    dispatch(
      getBoardSuccessPipeline({
        cards,
        columns,
        columnOrder: defaultColumnsOrder,
        disablePreloader,
      })
    )
  } catch (err) {
    dispatch(hasErrorPipeline(err))
  }
}

export const addEditColumnPipeline = ({
  campaignId,
  method,
  columnId,
  position,
  columnName,
  withoutRefetch,
}) => async (dispatch, getState) => {
  const currentCampaignData = currentOpenedCampaignSelector(getState())
  !withoutRefetch && dispatch(changeLoadingPipeline(true))
  const payload = {}
  try {
    if (method === 'remove') {
      payload.method = method
      payload.columnId = columnId
      payload.campaignId = campaignId || currentCampaignData.campaignId
    }
    if (method !== 'remove') {
      payload.campaignId = campaignId || currentCampaignData.campaignId
      payload.method = method
      payload.columnId = method === 'change' ? columnId : ''
      payload.position = position
      payload.columnName = columnName
    }

    const response = await httpService.addEditColumnPipeline(payload)

    if (response && !withoutRefetch) {
      dispatch(getPipelineData({ campaignId: campaignId || currentCampaignData.campaignId }))
    }
  } catch (err) {
    dispatch(hasErrorPipeline(err))
  }
}

export const addEditCardTaskPipeline = ({
  campaignId,
  method,
  inColumnId,
  subText,
  priority,
  dueDate,
  assignedTo,
  creatorId,
  cardId,
  withoutRefetch = false,
}) => async (dispatch, getState) => {
  const currentCampaignData = currentOpenedCampaignSelector(getState())

  method === 'change'
    ? dispatch(changeBoardCardLoadingPipeline(true))
    : dispatch(changeLoadingPipeline(true))

  const payload = {}
  try {
    if (method === 'remove') {
      payload.campaignId = campaignId || currentCampaignData.campaignId
      payload.method = method
      payload.cardId = cardId
    }
    if (method !== 'remove') {
      payload.campaignId = campaignId || currentCampaignData.campaignId
      payload.method = method
      payload.inColumnId = inColumnId
      payload.subText = subText ? subText?.trim() : ''
      payload.priority = priority || 'none'
      payload.dueDate = dueDate
      payload.assignedTo = assignedTo
      payload.creatorId = creatorId
      payload.cardId = cardId
    }

    const response = await httpService.addEditPipelineCards(payload)

    if (response && !withoutRefetch) {
      dispatch(
        getPipelineData({
          campaignId: campaignId || currentCampaignData.campaignId,
          disablePreloader: method === 'change' ? true : false,
        })
      )
    }
  } catch (err) {
    const { response } = err || {}

    if (response.status === API_STATUS_CODES.forbidden) {
      dispatch(setError(ERROR_MSG.creatorAlreadyInPipeline))
    }

    dispatch(hasErrorPipeline(err))
  }
}

export const addRemovePipelineComment = ({
  campaignId,
  method,
  cardId,
  commentId,
  commentBy,
  commentText,
  attachment,
}) => async (dispatch, getState) => {
  const currentCampaignData = currentOpenedCampaignSelector(getState())
  dispatch(changeBoardCardLoadingPipeline(true))
  const payload = {}
  try {
    if (method === 'remove') {
      payload.campaignId = campaignId || currentCampaignData.campaignId
      payload.method = method
      payload.cardId = cardId
      payload.commentId = commentId
    }
    if (method !== 'remove') {
      payload.campaignId = campaignId || currentCampaignData.campaignId
      payload.method = method
      payload.cardId = cardId
      payload.commentBy = commentBy
      payload.commentText = commentText
      payload.attachment = attachment
    }

    const response = await httpService.addRemovePipelineComment(payload)

    if (response) {
      dispatch(
        getPipelineData({
          campaignId: campaignId || currentCampaignData.campaignId,
          disablePreloader: true,
        })
      )
    }
  } catch (err) {
    dispatch(hasErrorPipeline(err))
  }
}

export const addEditPipelineData = ({
  campaignId,
  method,
  inColumnId,
  subText,
  priority,
  dueDate,
  assignedTo,
  creatorId,
  cardId,
  disablePreloader,
}) => async (dispatch, getState) => {
  const currentCampaignData = currentOpenedCampaignSelector(getState())
  !disablePreloader && dispatch(changeLoadingPipeline(true))
  const payload = {}
  try {
    if (method === 'remove') {
      payload.method = method
      payload.cardId = cardId
      payload.campaignId = campaignId || currentCampaignData.campaignId
    }
    if (method !== 'remove') {
      payload.campaignId = campaignId || currentCampaignData.campaignId
      payload.method = method
      payload.priority = priority
      payload.inColumnId = inColumnId
      payload.subText = subText
      payload.dueDate = dueDate
      payload.assignedTo = assignedTo
      payload.creatorId = creatorId
      payload.cardId = method === 'change' ? cardId : ''
    }

    const response = await httpService.addEditPipelineCards(payload)

    if (response) {
      dispatch(
        getPipelineData({
          campaignId: campaignId || currentCampaignData.campaignId,
          disablePreloader,
        })
      )
    }
  } catch (err) {
    dispatch(hasErrorPipeline(err))
  }
}

export const removeDemoCampaign = ({ history }) => async dispatch => {
  try {
    dispatch([
      setCurrentOpenedCampaign({ ...initState.currentOpenedCampaign }),
      changeDataCreatorList([]),
    ])
    dispatch([changeNewCampaignData([]), changeNewCampaignIsLoading(true)])

    const removeDemoCampaign = await httpService.removeDemoCampaign()

    if (removeDemoCampaign) {
      history.replace(`/campaigns`)
      dispatch([changeDataDashboard([]), changeContentListLoading(true), changeContentListData([])])
    }
  } catch (err) {
    console.error(err)
  }
}

export const getExploreData = ({ mentions, index }) => async dispatch => {
  try {
    const mentionsData = await httpService.getExploreData({
      mentions,
      index,
    })

    dispatch([
      changeCampaignTopCreators(mentionsData?.topCreators),
      changeCampaignTopHashtags(mentionsData?.topHashtags),
    ])
  } catch (err) {
    console.error(err)
  }
}

export const copyCampaign = ({ campaignId, newCampaignName }) => async dispatch => {
  try {
    const { campaignId: copyCampaignId } = await httpService.createCampaignByCopy({
      campaignId,
      newCampaignName,
    })

    if (copyCampaignId) {
      dispatch(changeNewCopyCampaignId(copyCampaignId))
    }
  } catch (err) {
    console.error(err)
  }
}

export const searchCampaignContentByLink = ({ campaignId, link }) => async dispatch => {
  dispatch(changeContentListLoading(true))
  try {
    const data = await httpService.searchCampaignContentByLink({ campaignId, link })

    dispatch(changeContentByBitlyLink(data))
  } catch (err) {
    console.error(err)
    dispatch(changeContentListLoading(false))
  } finally {
    dispatch(changeContentListLoading(false))
  }
}